import { FC, useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Unstable_Grid2'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import { JournalDailyWorkPaymentStatus } from '../../types/Journal'
import { Tenant, useAuth } from '../../context/AuthProvider'
import { AxiosError } from 'axios'
import useSnackbarMessages from '../../hooks/useSnackbarMessages'
import { GetPayrollDetailsResponse, JournalDailyWorkRequest } from '../../types/Payroll'
import ReportSkeletons from './ReportSkeletons'
import TextField from '@mui/material/TextField'
import { PaymentType, getFullName } from '../../types/Employee'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody'
import { fDateShort } from '../../utils/format-time'
import Divider from '@mui/material/Divider'
import { fNumber } from '../../utils/format-number'
import PayrollFilters from './PayrollFilters'
import dayjs, { Dayjs } from 'dayjs'
import { Farm } from '../../types/Farm'
import Button from '@mui/material/Button'
import DynamicDialog from '../../components/DynamicDialog'
import DialogContentText from '@mui/material/DialogContentText'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useNavigate } from 'react-router-dom'

const MIN_LOADING_TIME = 500

interface JournalDailyWorkData {
  id: number
  tenantId: number
  journalDailyWorkDetailIds: number[]
}

type JournalDailyWorkDict = { [key: number]: JournalDailyWorkData }

type CreatePayrollRequest = {
  createdByUserId: number
  journalDailyWorks: {
    id: number
    tenantId: number
    journalDailyWorkDetailIds: number[]
  }[]
  payrollDate: string | null
}

const PayrollCalculateView: FC = () => {
  const { auth } = useAuth() 
  const [isLoading, setIsLoading] = useState(true)
  const { showError, showWarning, showSuccess } = useSnackbarMessages()
  const [payrollDetails, setPayrollDetails] = useState<GetPayrollDetailsResponse>()
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null)
  const [selectedFarm, setSelectedFarm] = useState<Farm | null>(null)
  const [selectedJobTypeIds, setSelectedJobTypeIds] = useState<number[]>([])
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType | null>(null)
  const [selectedFromDate, setSelectedFromDate] = useState<Dayjs | null>(null)
  const [selectedToDate, setSelectedToDate] = useState<Dayjs | null>(null)
  const [openCreatePayrrollModal, setOpenCreatePayrrollModal] = useState(false)
  const [selectedPayrollDate, setSelectedPayrollDate] = useState<Dayjs | null>(dayjs())
  const navigate = useNavigate()

  const journalDailyWorkRequest: JournalDailyWorkRequest = {
    paymentStatus: JournalDailyWorkPaymentStatus.PendingPayment,
    tenantId: selectedTenant ? selectedTenant.id : null,
    farmId: selectedFarm ? selectedFarm.id : null,
    employeeJobTypeIds: selectedJobTypeIds,
    paymentTypeId: selectedPaymentType ? selectedPaymentType.id : null,
    payrollMasterId: null,
    fromDate: selectedFromDate ? selectedFromDate.format('YYYY-MM-DD') : null,
    toDate: selectedToDate ? selectedToDate.format('YYYY-MM-DD') : null,
  }

  const getJournalDailyWorkReports = async (controller: AbortController) => {
    setIsLoading(true)
    try {
      const fetchStart = Date.now()

      const responsePromise = axiosPrivate.post(
        endpoints.getJournalDailyWorksForPayroll,
        journalDailyWorkRequest,
        { signal: controller.signal }
      )

      const [response] = await Promise.all([
        responsePromise,
        new Promise((resolve) =>
          setTimeout(resolve, MIN_LOADING_TIME - (Date.now() - fetchStart))
        ), // Ensure a minimum loading time
      ])

    setPayrollDetails(response.data)
    setIsLoading(false)

    } catch (err: any) {
      const error = err as AxiosError
      if (error.name !== 'CanceledError') {
        setIsLoading(false)
        showError('Error al obtener los datos de la planilla')
      }
    }
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    if (isMounted) {
      getJournalDailyWorkReports(controller)
    }

    return () => {
      isMounted = false
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedFromDate,
    selectedToDate,
    selectedTenant,
    selectedFarm,
    selectedJobTypeIds,
    selectedPaymentType
  ])

  const handleOnConfirmCreatePayrroll = async (): Promise<void> => {     
    if (payrollDetails?.employeeWorkDetails.length === 0) {
      showWarning('La planilla no puede ser creada debido a que los filtros seleccionados no muestran resultados. Cambie los filtros y vuelva a intentar.');
      return
    }
  
    const journalDailyWorkDict: JournalDailyWorkDict = {}
  
    payrollDetails?.employeeWorkDetails.forEach(employeeWorkDetail => {
      employeeWorkDetail.journalDailyWorkDetails.forEach(journalDetail => {
        const { journalDailyWorkId } = journalDetail;
  
        if (!journalDailyWorkDict[journalDailyWorkId]) {
          journalDailyWorkDict[journalDailyWorkId] = {
            id: journalDailyWorkId,
            tenantId: journalDetail.journalDailyWork.tenant.id,
            journalDailyWorkDetailIds: []
          }
        }
  
        journalDailyWorkDict[journalDailyWorkId].journalDailyWorkDetailIds.push(journalDetail.id as number);
      })
    })
  
    const createPayrollRequest: CreatePayrollRequest = {
      createdByUserId: auth.user?.userid || 0,
      journalDailyWorks: Object.values(journalDailyWorkDict),
      payrollDate: selectedPayrollDate ? selectedPayrollDate.format('YYYY-MM-DD') : ''
    }
    
    await CreatePayroll(createPayrollRequest)
    setOpenCreatePayrrollModal(false)    
  }

  const CreatePayroll = async (createPayrollRequest: CreatePayrollRequest) => {
    // console.log(createPayrollRequest)
    setIsLoading(true)
    const controller = new AbortController()

    const SendCreatePayrollRequest = async () => {    
      try {
        const response = await axiosPrivate.post(
          endpoints.createPayroll,
          createPayrollRequest,
          { signal: controller.signal }
        )         
  
        if (response.status === 200) {
          showSuccess('Planilla creada exitosamente!')
          //SetTimeout to allow the user to see the success message and then navigate
          setTimeout(() => {
            navigate(0)
          }, MIN_LOADING_TIME)          
        }
      } catch (err: any) {
        const error = err as AxiosError<ApiError>
  
        if (error.name === 'CanceledError') {
          //console.log('The request has been canceled')
        } else {
          showError('Error al crear la planilla ' + error?.response?.data?.detail)
        }
      } finally {
        setIsLoading(false)
      }
    }

    SendCreatePayrollRequest()
  }

  const handleOnCloseCreatePayrroll = () => {
    setOpenCreatePayrrollModal(false)
  }

  const dialogAcceptButton = {
    text: 'Crear Planilla',
    action: handleOnConfirmCreatePayrroll,
  }

  const dialogCancelButton = {
    text: 'Cancelar',
    action: handleOnCloseCreatePayrroll,
  } 

  return (
    <Container>
      <Stack
        sx={{ mb: 1 }}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid xs={12} md={8}>
            <Typography variant='h4'>Calcular Planilla</Typography>
          </Grid>
          <Grid
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'flex-start', md: 'flex-end' },
            }}
          >
            {auth.user?.isAdmin && (
              <Button
                onClick={() => setOpenCreatePayrrollModal(true)}
                variant='contained'
                color='inherit'
              >
                Crear Planilla
              </Button>
            )}
          </Grid>
        </Grid>
      </Stack>

      <PayrollFilters
        tenants={payrollDetails?.tenants || []}
        selectedTenant={selectedTenant}
        setSelectedTenant={setSelectedTenant}
        farms={payrollDetails?.farms || []}
        selectedFarm={selectedFarm}
        setSelectedFarm={setSelectedFarm}
        employeeJobTypes={payrollDetails?.employeeJobTypes || []}
        selectedJobTypeIds={selectedJobTypeIds}
        setSelectedJobTypeIds={setSelectedJobTypeIds}
        selectedPaymentType={selectedPaymentType}
        setSelectedPaymentType={setSelectedPaymentType}
        selectedFromDate={selectedFromDate}
        onFromDateChange={setSelectedFromDate}
        selectedToDate={selectedToDate}
        onToDateChange={setSelectedToDate}
      />

      <Card>
        <CardContent>
          {isLoading ? (
            <ReportSkeletons count={5} />
          ) : (
            payrollDetails?.employeeWorkDetails.map((report, index) => (
              <Grid xs={12} container spacing={1} sx={{ ml: 1 }} key={index}>
                <Grid xs={12}>
                  <Typography variant='h6'>
                    {getFullName(report.employee)}
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: { xs: 'auto', md: 650 } }}
                      size='small'
                      aria-label='a dense table'
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Fecha</TableCell>
                          <TableCell>Trabajo</TableCell>
                          <TableCell align='right'>Cantidad</TableCell>
                          <TableCell align='right'>Precio</TableCell>
                          <TableCell align='right'>SubTotal</TableCell>
                          <TableCell>Finca</TableCell>
                          <TableCell>Perfil</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {report.journalDailyWorkDetails.map((detail) => (
                          <TableRow
                            key={detail.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell>
                              {fDateShort(
                                detail.journalDailyWork.journalDailyWorkDate
                              )}
                            </TableCell>
                            <TableCell>{detail.employeeJobType.name}</TableCell>
                            <TableCell align='right'>
                              {fNumber(detail.jobQuantity)}
                            </TableCell>
                            <TableCell align='right'>
                              {fNumber(detail.jobPrice)}
                            </TableCell>
                            <TableCell align='right'>
                              {fNumber(detail.dayPayment)}
                            </TableCell>
                            <TableCell>
                              {detail.journalDailyWork.farm.name}
                            </TableCell>
                            <TableCell>
                              {detail.journalDailyWork.tenant.name}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Grid container spacing={1}>
                    <Grid xs={12} md={6}>
                      <Typography
                        variant='body2'
                        fontWeight='bold'
                        sx={{ mx: 1 }}
                      >
                        Total = {fNumber(report.totalPayment)}
                      </Typography>
                    </Grid>

                    <Grid xs={12} md={6}>
                      <Typography variant='body2' sx={{ mx: 1 }}>
                        Pago en {report.employee.paymentTypeDescription}{' '}
                        {report.accountNumber
                          ? `(${report.accountNumber})`
                          : ''}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid xs={12} container sx={{ mx: 1, mt: 2, mb: 2 }}>
                    <Divider style={{ width: '100%' }} />
                  </Grid>
                </Grid>
              </Grid>
            ))
          )}

          <Grid xs={12} container sx={{ mx: 1, mt: 3, mb: 2 }}>
            <Divider style={{ width: '100%' }} />
          </Grid>

          <Grid xs={12} container spacing={1} sx={{ ml: 1 }}>
            <Grid xs={12}>
              <TextField
                label='Total Planilla'
                value={fNumber(payrollDetails?.totalPayrollPayment)}
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { fontWeight: 'bold' },
                }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} container spacing={1} sx={{ ml: 1, mt: 1 }}>
            {payrollDetails?.paymentTypeTotals.map((paymentTypeTotal) => (
              <Grid
                xs={12}
                md={6}
                key={paymentTypeTotal.paymentType}
                sx={{ mt: 1 }}
              >
                <TextField
                  label={`Total ${paymentTypeTotal.paymentType}`}
                  value={fNumber(paymentTypeTotal.totalPayment)}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    style: { fontWeight: 'bold' },
                  }}
                />
              </Grid>
            ))}
          </Grid>

          <Grid xs={12} container sx={{ mx: 1, mt: 1, mb: 2 }}>
            <Divider style={{ width: '100%' }} />
          </Grid>
          <DynamicDialog
            openDialog={openCreatePayrrollModal}
            dialogTitle='Crear Planilla'
            onCloseAction={handleOnCloseCreatePayrroll}
            buttonAccept={dialogAcceptButton}
            buttonCancel={dialogCancelButton}
          >
            <DialogContentText id='generic-dialog-description'>
              Crear planilla con los filtros seleccionados. ¿Desea continuar?
            </DialogContentText>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ mt: 3 }}
                label='Fecha de la Planilla'
                value={selectedPayrollDate}
                onChange={(newValue) => setSelectedPayrollDate(newValue)}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>
          </DynamicDialog>
        </CardContent>
      </Card>
    </Container>
  )
}

export default PayrollCalculateView
