import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { Tenant, User, useAuth } from "../context/AuthProvider"
import { endpoints } from "../api/constants"
import { getLocalStorageItem } from "../utils/localStorageUtils"
import { UserInfoResponse } from '../sections/login/LoginView'


const useRefreshToken = () => {
    const { setAuth } = useAuth()
    const axiosPrivate = useAxiosPrivate()

    const getCurrentSelectedTenant = (): Tenant => {
        const tenant = getLocalStorageItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_SELECTED_TENANT as string)
        return tenant && JSON.parse(tenant)
      }      
    
    const refresh = async (): Promise<string | void> => {        
        try {
            const response = await axiosPrivate.get(endpoints.getUserInfo)            
            const userInfoResponse = response.data as UserInfoResponse            
            const tenants = userInfoResponse.tenants
            const user = {
              userid: userInfoResponse.id,
              username: `${userInfoResponse.firstName} ${userInfoResponse.lastName}`,
              email: userInfoResponse.email,
              imageurl: userInfoResponse.imageUrl,
              isApprover: userInfoResponse.isApprover,
              isAdmin: userInfoResponse.isAdmin
            } as User
            const selectedTenant: Tenant = getCurrentSelectedTenant()
            setAuth((prev) => {             
                return {
                  ...prev,
                  // roles: response.data.roles, TODO: Implement role-based authorization
                  user: user,               
                  tenants: tenants,
                  selectedTenant: selectedTenant,
                }
              })            
        } catch (error) {
            console.error("Error refreshing token:", error)            
            throw error
        }        
    }
    return refresh
}

export default useRefreshToken