import { useNavigate } from "react-router-dom"
import { FC, useEffect } from "react"
import { useAuth } from "../../context/AuthProvider"
import { useMsal } from "@azure/msal-react"

const LogOut: FC = () => {
    const { setAuth } = useAuth()
    const navigate = useNavigate()
    const { instance } = useMsal()


    //uncomment the following line when enabling React.StrictMode
    // const effectRan = useRef<boolean>(false) //useRef is used to persist the value of a variable across renders without triggering a re-render when the value changes
    
    useEffect(() => {
        setAuth({})
        const logout = async () => {
            setAuth({})
            try {              
                instance.logout()                
                navigate('/login')
            } catch (err) {
                console.error(err)
            }
        }

        // if (effectRan.current === true || process.env.NODE_ENV !== 'development') {
            logout()          
        // }

        // return () => {           
        //     effectRan.current = true
        //   }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
}

export default LogOut