import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  GetPayrollDetailsResponse,
  JournalDailyWorkRequest,
} from '../../types/Payroll'
import { Tenant } from '../../context/AuthProvider'
import { Farm } from '../../types/Farm'
import { getFullName, PaymentType } from '../../types/Employee'
import { fDateShort } from '../../utils/format-time'
import { JournalDailyWorkPaymentStatus } from '../../types/Journal'
import { axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import { AxiosError } from 'axios'
import useSnackbarMessages from '../../hooks/useSnackbarMessages'
import ReportSkeletons from './ReportSkeletons'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import { fNumber } from '../../utils/format-number'
import Grid from '@mui/material/Unstable_Grid2'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import PayrollFilters from './PayrollFilters'
import { Dayjs } from 'dayjs'

const MIN_LOADING_TIME = 500

const PayrollPayNotIncluded: FC = () => {
  const { showError } = useSnackbarMessages()
  const [payrolDetails, setPayrolDetails] =
    useState<GetPayrollDetailsResponse>()
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null)
  const [selectedFarm, setSelectedFarm] = useState<Farm | null>(null)
  const [selectedJobTypeIds, setSelectedJobTypeIds] = useState<number[]>([])
  const [selectedPaymentType, setSelectedPaymentType] =
    useState<PaymentType | null>(null)
  const [selectedFromDate, setSelectedFromDate] = useState<Dayjs | null>(null)
  const [selectedToDate, setSelectedToDate] = useState<Dayjs | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const journalDailyWorkRequest: JournalDailyWorkRequest = useMemo(
    () => ({
      paymentStatus: JournalDailyWorkPaymentStatus.PendingPayment,
      tenantId: selectedTenant ? selectedTenant.id : null,
      farmId: selectedFarm ? selectedFarm.id : null,
      employeeJobTypeIds: selectedJobTypeIds,
      paymentTypeId: selectedPaymentType ? selectedPaymentType.id : null,
      payrollMasterId: null,
      fromDate: selectedFromDate ? selectedFromDate.format('YYYY-MM-DD') : null,
      toDate: selectedToDate ? selectedToDate.format('YYYY-MM-DD') : null,
    }),
    [
      selectedTenant,
      selectedFarm,
      selectedJobTypeIds,
      selectedPaymentType,
      selectedFromDate,
      selectedToDate,
    ]
  )

  const getJournalDailyWorkReports = useCallback(
    async (controller: AbortController) => {
      setIsLoading(true)
      try {
        const fetchStart = Date.now()

        const responsePromise = axiosPrivate.post(
          endpoints.getJournalDailyWorksForPayroll,
          journalDailyWorkRequest,
          { signal: controller.signal }
        )

        const [response] = await Promise.all([
          responsePromise,
          new Promise((resolve) =>
            setTimeout(resolve, MIN_LOADING_TIME - (Date.now() - fetchStart))
          ), // Ensure a minimum loading time
        ])

        const payrollResponse = response.data as GetPayrollDetailsResponse
        setPayrolDetails(payrollResponse)
        setIsLoading(false)
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          setIsLoading(false)
          showError('Error al obtener los datos de la planilla')
        }
      }
    },
    [journalDailyWorkRequest, showError]
  )

  useEffect(() => {
    const controller = new AbortController()
    getJournalDailyWorkReports(controller)
    return () => {
      controller.abort()
    }
  }, [
    selectedTenant,
    selectedFarm,
    selectedJobTypeIds,
    selectedPaymentType,
    getJournalDailyWorkReports,
  ])

  return (
    <>
      {payrolDetails?.employeeWorkDetails.length === 0 ? (
        <Typography variant='h6' sx={{ ml: 2, mt: 3 }}>
          No hay reportes no incluidos en la planilla.
        </Typography>
      ) : (
        <>
          <PayrollFilters
            tenants={payrolDetails?.tenants || []}
            selectedTenant={selectedTenant}
            setSelectedTenant={setSelectedTenant}
            farms={payrolDetails?.farms || []}
            selectedFarm={selectedFarm}
            setSelectedFarm={setSelectedFarm}
            employeeJobTypes={payrolDetails?.employeeJobTypes || []}
            selectedJobTypeIds={selectedJobTypeIds}
            setSelectedJobTypeIds={setSelectedJobTypeIds}
            selectedPaymentType={selectedPaymentType}
            setSelectedPaymentType={setSelectedPaymentType}
            selectedFromDate={selectedFromDate}
            onFromDateChange={setSelectedFromDate}
            selectedToDate={selectedToDate}
            onToDateChange={setSelectedToDate}
          />

          <Card>
            <CardContent>
              {isLoading ? (
                <ReportSkeletons count={5} />
              ) : (
                payrolDetails?.employeeWorkDetails.map((report, index) => (
                  <Grid
                    xs={12}
                    container
                    spacing={1}
                    sx={{ ml: 1 }}
                    key={index}
                  >
                    <Grid xs={12}>
                      <Grid xs={12} container>
                        <Grid xs={10}>
                          <Typography variant='h6' sx={{ ml: 1 }}>
                            {getFullName(report.employee)}
                          </Typography>
                        </Grid>
                      </Grid>

                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: { xs: 'auto', md: 650 } }}
                          size='small'
                          aria-label='a dense table'
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Fecha</TableCell>
                              <TableCell>Trabajo</TableCell>
                              <TableCell align='right'>Cantidad</TableCell>
                              <TableCell align='right'>Precio</TableCell>
                              <TableCell align='right'>SubTotal</TableCell>
                              <TableCell>Finca</TableCell>
                              <TableCell>Perfil</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {report.journalDailyWorkDetails.map((detail) => (
                              <TableRow
                                key={detail.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>
                                  {fDateShort(
                                    detail.journalDailyWork.journalDailyWorkDate
                                  )}
                                </TableCell>
                                <TableCell>
                                  {detail.employeeJobType.name}
                                </TableCell>
                                <TableCell align='right'>
                                  {fNumber(detail.jobQuantity)}
                                </TableCell>
                                <TableCell align='right'>
                                  {fNumber(detail.jobPrice)}
                                </TableCell>
                                <TableCell align='right'>
                                  {fNumber(detail.dayPayment)}
                                </TableCell>
                                <TableCell>
                                  {detail.journalDailyWork.farm.name}
                                </TableCell>
                                <TableCell>
                                  {detail.journalDailyWork.tenant.name}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <Grid container spacing={1}>
                        <Grid xs={12} md={6}>
                          <Typography
                            variant='body2'
                            fontWeight='bold'
                            sx={{ mx: 1 }}
                          >
                            Total = {fNumber(report.totalPayment)}
                          </Typography>
                        </Grid>

                        <Grid xs={12} md={6}>
                          <Typography variant='body2' sx={{ mx: 1 }}>
                            Pago en {report.employee.paymentTypeDescription}{' '}
                            {report.accountNumber
                              ? `(${report.accountNumber})`
                              : ''}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid xs={12} container sx={{ mx: 1, mt: 2, mb: 2 }}>
                        <Divider style={{ width: '100%' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              )}

              <Grid xs={12} container sx={{ mx: 1, mt: 3, mb: 2 }}>
                <Divider style={{ width: '100%' }} />
              </Grid>

              <Grid xs={12} container spacing={1} sx={{ ml: 1 }}>
                <TextField
                  label='Total Planilla'
                  value={fNumber(payrolDetails?.totalPayrollPayment)}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    style: { fontWeight: 'bold' },
                  }}
                />
              </Grid>

              <Grid xs={12} container spacing={1} sx={{ ml: 1, mt: 1 }}>
                {payrolDetails?.paymentTypeTotals.map((paymentTypeTotal) => (
                  <Grid
                    xs={12}
                    md={6}
                    key={paymentTypeTotal.paymentType}
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      label={`Total ${paymentTypeTotal.paymentType}`}
                      value={fNumber(paymentTypeTotal.totalPayment)}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        style: { fontWeight: 'bold' },
                      }}
                    />
                  </Grid>
                ))}
              </Grid>

              <Grid xs={12} container sx={{ mx: 1, mt: 1, mb: 2 }}>
                <Divider style={{ width: '100%' }} />
              </Grid>
            </CardContent>
          </Card>
        </>
      )}
    </>
  )
}

export default PayrollPayNotIncluded
