import { FC, useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Unstable_Grid2'
import { axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import { AxiosError } from 'axios'
import useSnackbarMessages from '../../hooks/useSnackbarMessages'
import { PayrollMaster, PayrollStatus } from '../../types/Payroll'
import ReportSkeletons from './ReportSkeletons'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody'
import { formatFullDateWithDay } from '../../utils/format-time'
import Divider from '@mui/material/Divider'
import { fNumber } from '../../utils/format-number'
import Fab from '@mui/material/Fab'
import Iconify from '../../components/iconify/iconify'
import { useNavigate } from 'react-router-dom'

const MIN_LOADING_TIME = 500

const PayrollView: FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { showError } = useSnackbarMessages()
  const [payrolls, setPayrolls] = useState<PayrollMaster[] | null>(null)
  const navigate = useNavigate()

  const getPayrolls = async (controller: AbortController) => {
    setIsLoading(true)
    try {
      const fetchStart = Date.now()     

      const responsePromise = await axiosPrivate.get(`${endpoints.getPayrollsMaster}${PayrollStatus.Paid}`,{ signal: controller.signal }
      )

      const [response] = await Promise.all([
        responsePromise,
        new Promise((resolve) =>
          setTimeout(resolve, MIN_LOADING_TIME - (Date.now() - fetchStart))
        ), // Ensure a minimum loading time
      ])
      
      setPayrolls(response.data)
      setIsLoading(false)
    } catch (err: any) {
      const error = err as AxiosError
      if (error.name !== 'CanceledError') {
        setIsLoading(false)
        showError('Error al obtener las planillas')
      }
    }
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    if (isMounted) {
      getPayrolls(controller)
    }

    return () => {
      isMounted = false
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Stack
        sx={{ mb: 1 }}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid xs={12} md={8}>
            <Typography variant='h4'>Planillas Pagadas</Typography>
          </Grid>
        </Grid>
      </Stack>

      <Card>
        <CardContent>
          {isLoading ? (
            <ReportSkeletons count={5} />
          ) : (
            payrolls?.map((payrollMaster, index) => (
              <Grid xs={12} container spacing={1} sx={{ ml: 1 }} key={index}>
                <Typography variant='h6'>
                  Fecha: {formatFullDateWithDay(payrollMaster.payrollDate)}
                </Typography>
                <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
                  <Table
                    sx={{ minWidth: { xs: 'auto', md: 650 } }}
                    size='small'
                    aria-label='a dense table'
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: { md: '40%' } }}>
                          Perfil
                        </TableCell>
                        <TableCell align='left'>Pagado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payrollMaster.payrolls.map((detail) => (
                        <TableRow
                          key={detail.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell>{detail.tenant?.name}</TableCell>
                          <TableCell align='left'>
                            {fNumber(detail.total)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Grid container xs={12} spacing={0}>
                  <Grid xs={6} md={'auto'} sx={{ ml: 1, width: { md: '35%' } }}>
                    <Stack
                      direction='row'
                      flexWrap='wrap'
                      justifyContent='flex-start'
                      alignItems='flex-start'
                    >
                      <Stack>
                        <Fab
                          variant='extended'
                          size='small'
                          color='inherit'
                          onClick={() => {
                            navigate(`/planilla/detalles/${payrollMaster.id}`, {
                              state: { currentPayrollMaster: payrollMaster },
                            })
                          }}
                        >
                          <Iconify
                            width={18}
                            icon='eva:eye-fill'
                            sx={{ mr: 1 }}
                          />
                          Ver detalle
                        </Fab>
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid>
                    <Typography
                      variant='body2'
                      fontWeight='bold'
                      sx={{ mt: 1.25 }}
                    >
                      Total = {fNumber(payrollMaster.total)}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid xs={12} container sx={{ mx: 1, mt: 2, mb: 2 }}>
                  <Divider style={{ width: '100%' }} />
                </Grid>
              </Grid>
            ))
          )}

          <Grid xs={12} container sx={{ mx: 1, mt: 3, mb: 2 }}>
            <Divider style={{ width: '100%' }} />
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}

export default PayrollView
