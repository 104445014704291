import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

const useSnackbarMessages = () => {
  const { enqueueSnackbar } = useSnackbar()

  const showSuccess = useCallback((message: string) => {
    enqueueSnackbar(message, { variant: 'success' })
  }, [enqueueSnackbar])

  const showError = useCallback((message: string) => {
    enqueueSnackbar(message, { variant: 'error' })
  }, [enqueueSnackbar])

  const showWarning = useCallback((message: string) => {
    enqueueSnackbar(message, { variant: 'warning' })
  }, [enqueueSnackbar])

  // Other variants: 'default', 'success', 'error', 'warning', 'info'

  return { showSuccess, showError, showWarning }
}

export default useSnackbarMessages