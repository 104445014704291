import { Helmet } from 'react-helmet-async'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import PayrollCalculateAndPayView from '../sections/Payroll/PayrollCalculateAndPayView'

const CalculatePayroll = () => {
  return (
    <>
      <Helmet>
        <title>Calcular y Pagar Planilla | Sistema Planilla</title>
      </Helmet>

      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <PayrollCalculateAndPayView />
      </MsalAuthenticationTemplate>
    </>
  )
}

export default CalculatePayroll
